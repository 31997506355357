import React from 'react'
import { prepareParagraph, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ stamp }) => {
  if (!stamp || stamp.isHide) return null

  return (
    <div className={styles.stamp}>
      <img className={styles.image} src={sanityImageUrl(stamp.image)} alt={stamp.image?.caption} />
      <div className={styles.wrap}>
        <h2 className={styles.title}>{stamp.title}</h2>
        <div className={styles.info}>
          <div className={styles.infoCol}>
            <p className={styles.description}>{stamp.info}</p>
            <p className={styles.price}>{stamp.price}</p>
            <p className={styles.available}>{stamp.available}</p>
          </div>
          <div className={styles.infoCol}>
            <div className={styles.faq}>
              {stamp.faq &&
                stamp.faq.map(faq => (
                  <div key={faq._key}>
                    <p className={styles.faqQuestion}>{faq.question}</p>
                    <p className={styles.faqAnswer}>{prepareParagraph(faq.answer)}</p>
                  </div>
                ))}
            </div>            
          </div>
        </div>
        <img className={styles.product} src={sanityImageUrl(stamp.stamp)} alt={stamp.stamp?.caption} />
      </div>
    </div>
  )
}
