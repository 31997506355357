import React from 'react'
import { sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ photoReviews }) => {
  if (!photoReviews || photoReviews.isHide) return null

  return (
    <div className={styles.reviews}>
      <div className={styles.wrap}>
        <div className={styles.top}>
          <h2 className={styles.title}>{photoReviews.title}</h2>
          {photoReviews.rating?.url && (
            <a href={photoReviews.rating.url} target="_blank" className={styles.rating}>
              {photoReviews.rating.starsImage && <img className={styles.ratingImage} src={sanityImageUrl(photoReviews.rating.starsImage)} alt={photoReviews.rating?.text}/>}
              {photoReviews.rating.text && <span className={styles.ratingText}>{photoReviews.rating.text}</span>}
            </a>
          )}
        </div>

        <div className={styles.list}>
          {photoReviews.items && photoReviews.items.map(item => (
            <div className={styles.item} key={item._key}>
              <div className={styles.itemTop}>
                <div className={styles.results}>
                  {item.results && item.results.map(result => (
                    <div className={styles.result} key={result._key}>
                      <img className={styles.resultImage} src={sanityImageUrl(result.image)} alt={result.image?.caption} />
                      <p className={styles.resultText}>{result.text}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.itemBottom}>
                <div className={styles.user}>
                  <p className={styles.name}>{item.name}</p>
                  <p className={styles.verified}>Verified buyer</p>
                </div> 
                <p className={styles.protocol}>
                  Using: <span>{item.protocol}</span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
