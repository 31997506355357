import React, { useState } from 'react'
import { urlWithSearchParamsHandler, prepareParagraph, sanityImageUrl, clickTrack } from '../../../utils/format'
import { PopupButton } from '@typeform/embed-react'
import { Link } from 'gatsby'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ info }) => {
  if (!info) return null

  const [safetyModalShow, setSafetyModalShow] = useState(false)
  
  const openSafetyModalHandler = () => {
    setSafetyModalShow(true)
  }

  const closeSafetyModalHandler = () => {
    setSafetyModalShow(false)
  }

  return (
    <div className={styles.info}>
      <div className={styles.wrap}>
        <div className={styles.customer}>
          <img src={sanityImageUrl(info.image)} alt={info.image?.caption} className={styles.image} />
        </div>
        <div className={styles.text}>
          {info.name && <span className={styles.name}>{info.name}</span>}
          <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: info.title }} />
          {info.rating?.url && (
            <Link className={styles.rating} to={info.rating.url}>
              {info.rating.starsImage && <img className={styles.ratingImage} src={sanityImageUrl(info.rating.starsImage)} alt={info.rating?.text}/>}
              {info.rating.text && <p>{info.rating.text}</p>}
            </Link>
          )}
          <ul className={styles.list}>
            {info.benefits &&
              info.benefits.map((item, index) => (
                <li className={styles.listItem} key={index}>
                  {prepareParagraph(item)}
                </li>
              ))}
          </ul>
          <div className={cx(styles.buttonWrap, {
            [styles.buttonWrap2]: !!info.button2Url
          })}>
            {info.buttonUrl?.includes('https://quiz/') ? (
              <PopupButton
                id={buttonUrl.replace('https://quiz/', '')}
                as="a"
                onReady={() => clickTrack(info.buttonText)}
                transitiveSearchParams={true}
                className={styles.button}
              >
                {info.buttonText}
              </PopupButton>
            ) : (
              <a href={info.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>
                {info.buttonText}
              </a>
            )}
            {info.button2Url && (
              <Link className={styles.button2} to={info.button2Url}>{info.button2Text}</Link>              
            )}
          </div>
          {info.importantSafetyModal?.modalText && (
            <div className={styles.safetyModalLink} onClick={openSafetyModalHandler}>ⓘ <span>{info.importantSafetyModal.modalText}</span></div>
          )}          
          {info.offLabels && <p className={styles.off}>{prepareParagraph(info.offLabels)}</p>}
        </div>
      </div>

      {safetyModalShow && (
        <div className={styles.safetyModal}>
          <div className={styles.safetyModalBg} onClick={closeSafetyModalHandler} />
          <div className={styles.safetyModalWrap}>
            <div className={styles.safetyModalBox}>
              <div className={styles.safetyModalClose} onClick={closeSafetyModalHandler} />
              <p className={styles.safetyModalTitle}>{info.importantSafetyModal.modalTitle}</p>
              <div className={styles.safetyModalContent} dangerouslySetInnerHTML={{ __html: info.importantSafetyModal.modalContent }} />
            </div>
          </div>
        </div>
      )}       
    </div>
  )
}
