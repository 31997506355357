import React from 'react'
import cx from 'classnames'
import { prepareParagraph } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ stacksUp }) => {
  if (!stacksUp || stacksUp.isHide) return null

  const getIconByValue = column => {
    if (column) {
      return <i className={styles.iconPlus} />
    }
  }

  return (
    <div className={styles.stacks}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{stacksUp.title}</h2>

        <div className={styles.info}>
          <p className={styles.infoText}>{prepareParagraph(stacksUp.description)}</p>
          <p className={styles.infoTag}>{stacksUp.product}</p>
        </div>

        <table className={styles.stacksInside}>
          <thead className={styles.row}>
            <tr>
              <td className={styles.cell}></td>
              <td className={cx(styles.cell, styles.cellAlign)}>
                <div dangerouslySetInnerHTML={{ __html: stacksUp.title_column_1 }} />
              </td>
              <td className={cx(styles.cell, styles.cellAlign)}>
                <div dangerouslySetInnerHTML={{ __html: stacksUp.title_column_2 }} />
              </td>
              <td className={cx(styles.cell, styles.cellAlign)}>
                <div dangerouslySetInnerHTML={{ __html: stacksUp.title_column_3 }} />
              </td>
            </tr>
          </thead>
          <tbody>
            {stacksUp.tableRows &&
              stacksUp.tableRows.map(row => (
                <tr className={cx(styles.row, styles.rowLine)} key={row._key}>
                  <td className={styles.cell}>{row.title}</td>
                  <td className={cx(styles.cell, styles.cellIcon)}>{getIconByValue(row.column_1)}</td>
                  <td className={cx(styles.cell, styles.cellIcon)}>{getIconByValue(row.column_2)}</td>
                  <td className={cx(styles.cell, styles.cellIcon)}>{getIconByValue(row.column_3)}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
